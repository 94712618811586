.products-list-categories {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin: 20px 0;

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }
    .products-list-category {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,.3);
        .products-list-category-content {
            padding: 5px 15px 0;
        }
    }
}

.products-list {
    list-style: none;
    display: grid;
    padding: 0;
    margin: 10px 0;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    text-align: left;
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }
    .products-list-item {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        transition: all 0.3s;
        strong {
            display: block;
        }
        a {
            text-decoration: none;
        }

        .content-list-item-content {
            display: block;
            padding: 10px;
        }
    }
}

.products-list-thumbnail {
    list-style: none;
    display: grid;
    padding: 0;
    margin: 10px 0;
    grid-template-columns: repeat(1,1fr);
    gap: 15px;
    text-align: left;
    .products-list-item {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        transition: all 0.3s;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 10px;
        strong {
            display: block;
        }
        a {
            text-decoration: none;
        }

        .products-list-item-content {
            display: block;
            padding: 10px;
        }
    }
}

.industries-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }
    a {
        text-decoration: none;
    }
    > li {
        background: $color-secondary;
        color: #fff;
    }
    .content-list-item-content {
        display: block;
        padding: 5px 10px 10px;
        color: #fff;
        strong {
            display: block;
        }
        small {
            display: block;
            font-size: 0.9em;
            line-height: 1.3em;
        }
    }
}

.fuel-services-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }
    .services-list-item {
        background-color: $color-primary;
        a {
            color: #fff;
            text-decoration: none;
        }
        img {
            width: 100%;

        }
        .content-list-item-content {
            display: block;
            padding: 15px;
            strong {
                display: block;
                font-size: 1.3em;
                margin: 0 0 5px;
            }
        }
    }
}

.diesel-fuel-item-list {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    text-align: center;

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }

    img {
        padding: 15px 10px;
        max-width: 250px;
    }
    .content-list-item-content {
        text-align: left;
    }
}

.lube-group-list-categories {
    .products-list-category {
        display: flex;
        @media screen and (max-width: 675px) {
            flex-direction: column;
        }
        .products-list-category-image {
            width: 30%;

            @media screen and (max-width: 675px) {
                width: 100%;
            }
        }
        .products-list-category-content {
            flex: 1;

            h2 {
                font-size: 1.3em;
            }
        }
    }
}

.product-accordion {
    margin-bottom: 20px;

    .card {
        border-radius: 0;
        border: 1px solid #fff;
        border-width: 1px 0 0 0;

        .card-header {
            background: $color-primary;
            color: $color-primary-tint;
        }
        .card-body {
            border: 1px solid #ddd;
        }
    }
    .product-collapse {
        font-size: 1.1em;
        cursor: pointer;
        position: relative;
        font-weight: bold;
        padding: 0 50px 0 0;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 7px;
            width: 20px;
            height: 20px;
            border: 2px solid $color-secondary-tint;
            border-width: 2px 2px 0 0;
            transform: rotate(-45deg);
        }
        &.collapsed:after {
            top: -3px;
            transform: rotate(135deg);
        }
    }
}

.product-file-list {
    .crafty-block-list-title {
        display: none;
    }
    .crafty-block-list-item {
        margin: 0 0 10px;

        a {
            display: block;
            font-size: 1.1em;
            padding: 15px 10px;
            background: $color-quaternary;
            color: $color-quaternary-tint;
            text-align: center;
            font-weight: bold;
        }
        &:nth-child(n+2) {
            a {
                padding: 10px 10px;
                background: $color-secondary;
                color: $color-secondary-tint;
            }
        }
    }
}
