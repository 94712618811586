html {
    height: 100%;
}
body.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}
table p {
    margin: 0 0 5px;
}
#main-header {
    background: $color-white;
    position: relative;
    z-index: 2;
}
#main-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px 25px;
    @media screen and (max-width: 675px) {
        align-items: flex-start;
        flex-direction: column-reverse;
        padding: 0;
    }
}
#main-logo {
    width: 200px;
    @media screen and (max-width: 768px) {
        width: 200px;
        margin: -5px 0 0;
        padding: 20px;
    }
}
#main-header-content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        width: 100%;
        background: #f1f1f1;
        border-bottom: 1px solid #eee;
        padding: 0;
    }
}
.search-form {
    display: flex;
    @media screen and (max-width: 576px) {
        margin-right: 5px;
    }
    input[type="search"] {
        background: $gray-background;
        border: none;
        outline: none;
        margin: 0;
        line-height: 30px;
        padding: 5px 10px;
        flex:1;
        @media screen and (max-width: 576px) {
            line-height: 25px;
            width: 140px;
            padding-right: 5px;
        }
    }
    button {
        background: $color-primary;
        color: $color-primary-tint;
        border: none;
        outline: none;
        margin: 0;
        line-height: 30px;
        padding: 5px 10px;
        width: 40px;
        flex:1;
        @media screen and (max-width: 576px) {
            line-height: 25px;
            padding: 5px;
        }
    }
}
#langnav {
    margin-left: 20px;
    .dropdown {
        background-color: $gray-background;
        .dropdown-toggle {
            display: block;
            line-height: 30px;
            padding: 5px 10px;
            text-decoration: none;
            img {
                width: 16px;
                vertical-align: middle;
                margin: 0 5px 3px 3px;
            }
            &::after {
                margin-left: 1em;
            }
            @media screen and (max-width: 576px) {
                line-height: 25px;
                padding: 5px;
            }
        }
        .dropdown-menu {
            border-radius: 0;
            min-width: 0;
            border: none;
            background-color: $gray-background;
            padding: 0;
            margin: 1px 0 0 0;
            width: 100%;
            a {
                display: block;
                line-height: 22px;
                padding: 10px;
                &:hover {
                    text-decoration: none;
                    background: $color-secondary;
                    color: $color-secondary-tint;
                }
            }
        }
    }
}
#header-contact {
    .crafty-block-list {
        display: flex;
    }
    .crafty-block-list-item {
        position: relative;
        padding: 0 20px 0 35px;
        border-right: 1px solid #ddd;
        margin: 0 0 0 20px;
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding: 0 10px;
            flex: 1;
            margin: 0;
        }
        .crafty-block-list-image {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -15px;
            img {
                height: 20px;
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        .crafty-block-list-title {
            font-size: 0.8em;
            line-height: 1.1em;
            color: #808080;
            margin: 0;
            padding: 0;
        }
        .crafty-block-list-description {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
        &:last-child {
            padding-right: 0;
            border-right: none;
        }
        @media screen and (max-width: 768px) {
            font-size: 0.9rem;
        }
    }
}
#main-nav {
    color:  #fff;
    background-color: $color-secondary;
    flex:1;
    margin: 0;
	text-align:left;
    position: relative;
    font-size: 0;
    font-family: $font-family-heading;

    a {
        font-size: 1.2em;
        color: #fff;
        font-weight: bolder;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    ul.menu {
        list-style: none;
        padding: 0;
        margin: 0;
        font-weight: 500;
        font-size: 0.9rem;
        display: flex;
        width: 100%;

        @media screen and (max-width: 768px) {
            font-size: 0.9rem;
        }
        &> li {
            flex: 1;
            text-align: center;
        }
        &> li > a {
            display: block;
            line-height: 56px;
            padding: 0;
            white-space: nowrap;
        }
        &> li:first-child {
            flex-grow: 0;
            a {
                padding: 0 20px;
            }
            span {
                display: none;
            }
        }
        &> li:nth-last-child(-n+2) {
            background-color: $color-primary;
            flex: 0;
            a {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
        &> li:hover {
            background-color: #000;
            a {
                text-decoration: none;
            }
        }
    }
}

.main-nav-subnav {
	overflow: hidden;
	max-height: 0;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 0.9em;
	text-align: left;
	line-height: 1.6em;
	text-transform: none;
	margin-top: -5px;
	padding: 0;
	margin: 0;
    transition: max-height 0.4s;
    z-index: 9999;
    width: 100%;
    min-width: 200px;
    font-family: $font-family-general;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}
#main-nav .menu > li:hover > .main-nav-subnav {
	max-height: 600px;
}
.main-nav-subnav li {
	display: block;
    clear: both;
    padding: 0;
    margin: 0;
}
.main-nav-subnav li.section-seperator {
    border-top: 1px solid lighten($color-primary, 20);
}
.main-nav-subnav a {
    padding: 8px 10px;
    display: block;
    position: relative;
    background: $color-primary;
}
.main-nav-subnav a:hover {
    text-decoration: none;
    background: #333;
}


/** MOBILE NAV **/
#main-nav .mobile-toggle {
	display: none;
	width: 100%;
    font-size: 30px;

    @media screen and (max-width: 576px) {
        font-size: 30px;
    }
}
#main-nav #mobile-toggle {
	display: none;
}

@media screen and (max-width: 675px) {
    #main-nav ul.menu > li.contact-nav {
        a {
            background: transparent;
            padding: 0 16px;
            display: block;
            border-radius: 0;
        }
    }
    .main-nav-subnav,
    .main-nav-down-arrow {
        display: none;
    }
	#main-nav {
        position: absolute;
        top: 50px;
        right: 30px;
        background: transparent;
    }
	#main-nav .mobile-toggle {
		text-align: center;
		color:$color-primary;
		display: block;
		position: relative;
        border-radius: 20px;
		margin: 10px 0 0 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        z-index: 9999;
        background: #fff;
	}
	#main-nav .main-nav-container {
        overflow: hidden;
        position: fixed;
        top: 40px;
        left: -100%;
        height: 100%;
        width: 100%;
        z-index: 9998;
        background: $color-primary;
        transition: left 0.4s ease-in-out;
        align-items: flex-start;

        #site-nav {
            width: 100%;
        }

        ul.menu {
            display: flex;
            flex-direction: column;
            margin: 80px 0 0 0;
            width: 100%;
            font-size: 1.1rem;
            border-top :1px solid #fff;

            li {
                width: 100%;
            }

            &> li:first-child {
                span {
                    display: inline-block;
                }
            }
            a {
                color: #fff;
                display: block;
                width: 100%;
                border-bottom: 1px solid rgba(255,255,255,0.4);
                line-height: 45px;
                padding: 0;
            }
            a:hover,
            a:active {
                color: #fff;
                text-decoration: none;
            }
            small {
                display: none;
            }
        }
	}
	#mobile-toggle:checked ~ .main-nav-container  {
		left:0;
		border-bottom: 1px solid rgba(255,255,255,0.6);
    }
    .mobile-toggle {
        span {
            display: inline;
        }
        span, small {
            font-size: 1em;
        }
        small {
            display: none;
        }
    }
    .mobile-toggle.opening {
        span {
            display: none;
        }
        small {
            display: inline;
        }
    }

    .main-nav-about {
        ul.main-nav-subnav {
            max-height: 600px;
            position: relative;
            top: 0;
            display: block;
            font-size: 0.9em;

            a {
                line-height: 20px !important;
                &:before {
                    content: "-";
                }
            }
        }
    }
}

.page-banner {
    img {
        width: 100%;
    }
}

.content-container {
    box-sizing: border-box;
    max-width:1200px;
    margin:0 auto;
    padding:0;
    width:100%;
    @media screen and (max-width:768px){
        padding: 0;
    }
    @media screen and (max-width:576px){
        padding: 0;
    }
    &.padded {
        padding: 30px 40px;
        background: #fff;

        @media screen and (max-width:768px){
            padding: 20px;
        }
    }
}

.content-section-header-group {
    margin: 0 0 2em;
    h1 {
        font-weight: bold;
        font-size: 2.4em;
        line-height: 1.2em;
        padding: 0;
        margin: 0 0 10px;
        strong {
            color: $color-primary;
        }
        @media screen and (max-width:576px){
            font-size: 2.0em;
        }
    }
    h2 {
        font-weight: normal;
        font-size: 1.6em;
        line-height: 1.2em;
        font-weight: 600;
        opacity: 0.8;
        padding: 0;
        margin: 0;
        @media screen and (max-width:576px){
            font-size: 1.3em;
        }
    }
}
.secondary-heading {
    .content-section-header-group {
        margin: 0 0 1.5em;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        h1 {
            color: #222;
            font-size: 1.8em;
            line-height: 1.4em;
            font-weight: bold;
            padding: 0;
            margin: 0;
        }
        h2 {
            color: $gray-color;
            font-size: 1em;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-weight: normal;
        }
    }
}

#footer-copy {
    background: darken($color-secondary,10);
    color: $color-secondary-tint;
    text-align: center;
    font-size: 12px;
    line-height: 2em;
    padding: 3px 0 0 0;
    border: none;
}

#main-footer {
    background: lighten($color-secondary,6);
    color: $color-secondary-tint;
    border-top: 6px solid $color-primary;
    margin: 0;
    padding: 40px;
    position: relative;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 675px) {
        grid-template-columns: 1fr;
        padding: 20px;
    }
    a {
        color: $color-secondary-tint;
        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
    }

    .footer-site-navigation {
        list-style: none;
        padding: 0;
        margin: 0;
        small {
            padding-right: 5px;
        }
    }
    h5 {
        font-size: 1.8em;
        text-transform: uppercase;
        color: $color-primary
    }
}
.contact-social-buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.breadcrumb {
    border-radius: 0;
    background: #fff;
    padding: 0.5em 10px 0.5em 17px;
    font-size: 0.8em;
    margin:  0;
    line-height: 1.2em;
    a,strong {
        margin: 0 3px;
    }
}

.btn {
    font-family: $font-family-heading;
    font-weight: bold;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.btn-rounded {
    border-radius: 100px;
}

.block-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    li {
        display: block;
        margin: 0 0 5px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: block;
            padding: 0 3em 0 15px;
            background: $color-primary;
            color: $color-primary-tint;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 2.6em;
            font-weight: bold;
            position: relative;
            transition: background-color 0.3s;
            &:after {
                font-family: $font-icon;
                content: "\f0da";
                display: block;
                width: 2.6em;
                background-color: darken($color-primary,6);
                text-align: center;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                transition: background-color 0.3s;
            }
            &:hover {
                text-decoration: none;
                background-color: $color-secondary;
                &:after {
                    background-color: darken($color-secondary,6);
                }
            }
        }
    }
}
.icon-button {
    display: inline-block;
    padding: 0 15px 0 3.5em;
    background: $color-primary;
    color: $color-primary-tint;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 2.6em;
    font-weight: bold;
    position: relative;
    transition: background-color 0.3s;
    i {
        display: block;
        line-height: 2.6em;
        width: 2.6em;
        background-color: darken($color-primary,6);
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        transition: background-color 0.3s;
    }
    &.icon-button-after {
        padding: 0 3.5em 0 15px;
        i {
            left: auto;
            right: 0;
        }
    }
    &.icon-button-sm {
        font-size: 0.9rem;
        line-height: 2.2rem;
        font-weight: normal;
    }
    &.icon-button-secondary {
        background-color: $color-secondary;
        i {
            background-color: darken($color-secondary,5);
        }
    }
    &.icon-button-tertiary {
        background-color: $color-tertiary;
        i {
            background-color: darken($color-tertiary,5);
        }
    }
    &.icon-button-quaternary {
        background-color: $color-quaternary;
        i {
            background-color: darken($color-quaternary,5);
        }
    }

    &:hover {
        text-decoration: none;
        background: $color-secondary;
        color: $color-secondary-tint;
        i {
            background-color: darken($color-secondary,8);
        }
    }
}

.page-content-header {
    background: $color-primary url(/theme/static/images/metro-gimmic.png) no-repeat right center;
    background-size: auto 100% ;
    padding: 40px 240px 40px 40px;
    color: #fff;

    .content-section-header-group  {
        h1 {
            strong {
                color: #000;
            }
        }
        h2 {
            opacity: 1;
        }
    }

    @media screen and (max-width: 675px) {
        padding-right: 120px;
        background-position: right -130px center;
    }
    @media screen and (max-width: 500px) {
        padding-right: 40px;
        background-image: none;
    }

    .page-content-header-group {
        margin: 0 0 25px;
    }
    .page-content-header-content {
        display: flex;
        font-size: 1.1em;

        @media screen and (max-width: 675px) {
            flex-direction: column;
        }
    }
    .page-content-header-image {
        width: 160px;
        margin-right: 20px;
        @media screen and (max-width: 675px) {
            width: 80px;
            margin: 0 0 10px;
        }
    }
}

.site-contact-section {
    background-color: #fff;
    color: #222;
    display: flex;
    justify-content: space-between;

    border-left: 6px solid $color-primary;
    padding: 15px 20px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }

    .site-contact-title {
        p {
            margin: 0;
            padding: 0;
        }
        h3 {
            margin: 0;
            padding: 0;
            line-height: 1.1em;
        }
    }
    .site-contact-buttons {
        display: flex;
        align-items: center;
        @media screen and (max-width: 675px) {
            flex-direction: column;
        }
        &>a {
            margin-left: 10px;
            @media screen and (max-width: 960px) {
                margin: 10px 10px 0 0;
            }
            @media screen and (max-width: 675px) {
                width: 100%;
            }
        }
    }
}
