.header-slideshow {
    margin: 0;
    position: relative;
    z-index: 1;
    .slick-dots {
        li {
            background: #fff;
        }
    }
}
.crafty-block-slideshow-slide {
    position: relative;
    .crafty-block-slideshow-bg {
        &> img {
            width: 100%;
        }
    }
    .crafty-block-slideshow-content {
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &>div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: left;
            align-items: center;
        }
        img {
            max-width: 40%;
            max-height: 100%;
        }
    }
}
.slick-slide {
    .crafty-block-slideshow-content {
        opacity: 0;
        transition: all 0.6s;
    }
}
.slick-slide:nth-child(1) {
    .crafty-block-slideshow-content {
        &>div {
            padding-right: 2%;
            justify-content: flex-end;
            align-items: end;
            padding-bottom: 2%;
        }
    }
}
.slick-slide:nth-child(2) {
    .crafty-block-slideshow-content {
        &>div {
            justify-content: center;
        }
    }
}
.slick-active {
    .crafty-block-slideshow-content {
        opacity: 1;
        transform: translateY(0);
        animation-name: slide-content;
        animation-duration: 1s;
    }
}
@keyframes slide-content {
    0%   {opacity: 0;transform: translateY(60%);}
    50%   {opacity: 0;transform: translateY(60%);}
    90%   {opacity: 1;transform: translateY(-30px);}
    100% {opacity: 1;transform: translateY(0);}
}
.page-hero-about {
    background: #fff url(/theme/static/images/metro-gimmic.png) no-repeat 130%;
    background-size: auto 100% ;
    padding: 40px 240px 40px 40px;

    @media screen and (max-width: 960px) {
        background: #fff;
        padding: 20px;
    }
    @media screen and (max-width: 500px) {
        padding-right: 40px;
        background-image: none;
    }

    .content-section-header-group {
        margin: 0;
    }
    .hero-about-content {
        display: flex;
        font-size: 1.1em;
        gap: 20px;
        margin: 15px 0 0;
        justify-content: center;
        align-items: center;
        .hero-about-content-image {
            max-width: 100px;
        }

        &.horizontal {
            flex-direction: column;

            .hero-about-content-image {
                max-width: 100%;
            }
        }
    }
}
.home-fuel-section {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 1.1em;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 675px) {
        grid-template-columns: repeat(1, 1fr);
        .block-nav-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }
    }
    @media screen and (max-width: 576px) {
        .block-nav-list {
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;
        }
    }

    &>div {
        padding:25px;
    }
    h3 {
        font-weight: normal;
        font-size: 1.8em;
        line-height: 1em;
        margin: 0 0 10px;
        small {
            font-size: 0.7em;
            line-height: 1em;
        }
    }
    .home-fuel-intro {
        padding: 50px 30px;
        background: $color-primary url(/theme/static/images/metro-gimmic.png) no-repeat right bottom;
        background-size: 70% auto;

        @media screen and (max-width: 675px) {
            padding: 30px 30px 0;
        }

        h2 {
            font-size: 2.2em;
            line-height: 1.1em;
            strong {
                color: #fff;
            }
        }
        p {
            margin: 2em 0;
        }
    }
    .home-fuel-products {
        background: lighten($color-primary,30);
    }
    .home-fuel-services {
        background: lighten($color-primary,18);
    }
    .home-fuel-bulk {
        background: lighten($color-primary,15);
        grid-column: 1 / span 2;

        display: grid;
        grid-template-columns: repeat(2,1fr);

        & > div:last-child {
            margin-left: 20px;
        }

        @media screen and (max-width: 960px) {
            grid-column: auto;
            grid-template-columns: repeat(1,1fr);

            & > div:last-child {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
    .home-fuel-storage {
        background: lighten($color-primary,10);

        @media screen and (max-width: 960px) {
            grid-column: 1 / span 2;
        }
        @media screen and (max-width: 675px) {
            grid-column: auto;
        }
    }
    .content-image {
        margin-bottom: 16px;
    }
}
.home-lubricant-section {
    margin: 40px 0;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 10px;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
    }

    h2 {
        font-size: 2.2em;
        line-height: 1.2em;
        strong {
            color: $color-tertiary;
        }
    }
    .home-lubricant-intro {
        background: #fff;
        padding:30px 30px 40px;
        position: relative;
        @media screen and (max-width: 960px) {
            padding-bottom: 30px;
        }
        .content-image {
            margin: 20px 5px;

            @media screen and (max-width: 960px) {
                max-width: 400px;
                margin: 30px auto 0;
            }
        }
        .lubricant-arrows {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 0.8em;
            line-height: 1em;
            display: block;
            @media screen and (max-width: 960px) {
                display: none;
            }
            .lubricant-arrow-service {
                display: block;
                position: absolute;
                left: 30px;
                bottom: 30px;
                & > i {
                    display: block;
                    font-size: 18px;
                    color: $color-tertiary;
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    text-transform: uppercase;
                }
            }
            .lubricant-arrow-product {
                display: block;
                position: absolute;
                right: 30px;
                bottom: 30px;
                & > i {
                    font-size: 18px;
                    display: block;
                    color: $color-tertiary;
                    position: absolute;
                    top: -3px;
                    bottom: 0;
                    right: -12px;
                }
            }
        }
    }
    .home-lubricant-product {
        background: lighten($color-tertiary,40);
        padding:30px;
        .block-nav-list {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            @media screen and (max-width: 576px) {
                grid-template-columns: repeat(1,1fr);
            }
            gap: 5px;
            li {
                margin: 0;
                a {
                    background: $color-tertiary;
                    color: $color-tertiary-tint;
                    &:after {
                        background-color: darken($color-tertiary,6);
                    }
                    &:hover {
                        background-color: $color-secondary;
                        &:after {
                            background-color: darken($color-secondary,6);
                        }
                    }
                }
            }
        }
    }
    .home-lubricant-service {
        grid-column: 1 / span 2;
        @media screen and (max-width: 960px) {
            grid-column: auto;
        }
    }
}


.service-list {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 5px;

    @media screen and (max-width: 960px) {
        grid-template-columns: repeat(3,1fr);
    }
    @media screen and (max-width: 675px) {
        grid-template-columns: repeat(2,1fr);
    }
    .service-list-item {
        background: $color-tertiary;
        color: $color-tertiary-tint;
        cursor: pointer;
        transition: background-color 0.4s;
        &:nth-child(even) {
            background: darken($color-tertiary,6);
        }
        &:hover {
            background-color: $color-secondary;
        }
        a {
            color: $color-tertiary-tint;
            text-decoration: none;
        }
        img {
            margin: 0 0 10px;
        }
        strong {
            display: block;
            padding: 0 10px;
            font-size: 1.3em;
            line-height: 1.2em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        span {
            display: block;
            padding: 10px;
            font-size: 0.9em;
            line-height: 1.2em;
        }
    }
}

.home-contact-section {
    background-color: lighten($color-secondary,6);
    color: $color-secondary-tint;
    display: flex;
    justify-content: space-between;

    border-left: 6px solid $color-primary;
    padding: 15px 20px;

    @media screen and (max-width: 960px) {
        flex-direction: column;
    }

    .home-contact-title {
        p {
            margin: 0;
            padding: 0;
        }
        h3 {
            margin: 0;
            padding: 0;
            line-height: 1.1em;
        }
    }
    .home-contact-buttons {
        display: flex;
        align-items: center;
        @media screen and (max-width: 675px) {
            flex-direction: column;
        }
        &>a {
            margin-left: 10px;
            @media screen and (max-width: 960px) {
                margin: 10px 10px 0 0;
            }
            @media screen and (max-width: 675px) {
                width: 100%;
            }
        }
    }
}

.home-equiptment-section {
    margin: 40px 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 10px;

    @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;
    }

    .home-equiptment-intro {
        border-top: 6px solid $color-quaternary;
        background: #fff;
        padding: 30px;
        h2 {
            line-height: 1.2em;
            strong {
                color: $color-quaternary;
            }
        }
        p{

            font-size: 0.9em;
            line-height: 1.2em;
        }
    }
    .home-equiptment-products {

    }
    .equiptment-list {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        @media screen and (max-width: 675px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 576px) {
            grid-template-columns: 1fr;
        }
        .equiptment-list-item {
            border-top: 6px solid $color-quaternary;
            background: #fff;
            color: $color-secondary;
            cursor: pointer;
            transition: background-color 0.4s;
            &:hover {
                background-color: $color-quaternary;
                a {
                    color: $color-quaternary-tint;
                }
            }
            a {
                text-decoration: none;
            }
            img {
                margin: 0 0 10px;
            }
            strong {
                display: block;
                padding: 0 15px;
                font-size: 1.3em;
                line-height: 1.2em;
                text-transform: uppercase;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            span {
                display: block;
                padding: 10px 15px;
                font-size: 0.9em;
                line-height: 1.2em;
            }
        }
    }
}
