/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 8, 2021 */



@font-face {
    font-family: 'WF PT Sans';
    src: url('ptsans-regular-webfont.woff2') format('woff2'),
         url('ptsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'WF PT Sans';
    src: url('ptsans-bolditalic-webfont.woff2') format('woff2'),
         url('ptsans-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'WF PT Sans';
    src: url('ptsans-bold-webfont.woff2') format('woff2'),
         url('ptsans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'WF PT Sans';
    src: url('ptsans-italic-webfont.woff2') format('woff2'),
         url('ptsans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}
