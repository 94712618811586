/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 27, 2019 */



@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-italic-webfont.woff2') format('woff2'),
         url('kanit-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-medium-webfont.woff2') format('woff2'),
         url('kanit-medium-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-mediumitalic-webfont.woff2') format('woff2'),
         url('kanit-mediumitalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-regular-webfont.woff2') format('woff2'),
         url('kanit-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-light-webfont.woff2') format('woff2'),
         url('kanit-light-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: normal;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-lightitalic-webfont.woff2') format('woff2'),
         url('kanit-lightitalic-webfont.woff') format('woff');
    font-weight: lighter;
    font-style: italic;

}